console.log("hi");
let date;

// calendar
function calendar() {
  const section = document.getElementById("calendar");
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const today = new Date();

  for (let i = 1; i <= 365; i++) {
    const item = document.createElement("div");
    const date = new Date(new Date().getFullYear(), 0, i);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const monthString = ("0" + (date.getMonth() + 1)).slice(-2);
    const dayString = ("0" + date.getDate()).slice(-2);

    item.innerHTML = `
      <div class="circle"></div>
      <small>
      <p>${monthString}</p>
      <p>${dayString}</p>
      </small>
      `;
    item.classList.add(month);

    // detect if date has happened, is happening, or will happen

    today.setHours(0, 0, 0, 0);
    if (date < today) {
      item.classList.add("past");
    } else if (date.toDateString() === today.toDateString()) {
      item.classList.add("today");
    } else {
      item.classList.add("future");
    }

    // mark weekends
    if (date.getDay() === 0 || date.getDay() === 6) {
      item.classList.add("weekend");
    }

    // set unique id
    item.id = `${month}_${day}`;

    // add to page
    section.appendChild(item);
  }
}
calendar();

// toggle mode
function toggleDiv() {
  const toggleDiv = document.getElementById("toggle");

  toggleDiv.addEventListener("click", function () {
    toggleDiv.classList.toggle("on");
    document.body.classList.toggle("print");
    window.scrollTo(0, 0);
  });
}
toggleDiv();

// progress pill
function yearProgress() {
  const progressDiv = document.createElement("div");
  progressDiv.id = "progress";

  const currentDate = new Date();
  const currentDayOfYear = Math.ceil(
    (currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000
  );
  const totalDaysInYear = 365;
  const remainingDays = totalDaysInYear - currentDayOfYear;

  progressDiv.innerHTML = `
    <p>${currentDayOfYear} / ${remainingDays}</p>
  `;

  document.body.appendChild(progressDiv);
}
yearProgress();

// current date
function currentDate() {
  const dateDiv = document.createElement("div");
  dateDiv.id = "date";
  date = new Date();
  const dateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  dateDiv.innerHTML = `
    <p>${dateString}</p>
  `;
  document.body.appendChild(dateDiv);
}
currentDate();

// current time
function currentTime() {
  const timeDiv = document.createElement("div");
  timeDiv.id = "time";
  const timeString = date.toLocaleTimeString();

  timeDiv.innerHTML = `
    <p>${timeString}</p>
  `;
  document.body.appendChild(timeDiv);

  setInterval(function () {
    const time = new Date().toLocaleTimeString();
    // Update the date element with the current date
    document.getElementById("time").innerHTML = time;
  }, 1000);
}
currentTime();

// themes


function toggleTheme() {
  const themes = {
    "bw": "Dark",
    "wb": "Light",
    "cc-29": "CC-29"
  };
  
  const themeButtons = document.querySelectorAll(".option");
  const body = document.body;
  
  for (let i = 0; i < themeButtons.length; i++) {
    themeButtons[i].addEventListener("click", function () {
      for (let [theme, name] of Object.entries(themes)) {
        const themeClass = `theme-${theme}`;
        if (theme === this.id) {
          this.classList.add("active");
          body.classList.add(themeClass);
          console.log(`${name} theme`);
        } else {
          const otherButton = document.getElementById(theme);
          otherButton.classList.remove("active");
          body.classList.remove(themeClass);
        }
      }
    });
  }
}

toggleTheme();
